<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="إضافة عمل">
          <b-row>
            <b-col md="3" xl="3">
              <b-form-group label-for="delimiter">
                <label> العمل</label>
                <b-form-input v-model="workName" id="input-default" />
              </b-form-group>
            </b-col>
            <b-col md="3" xl="3">
              <b-form-group label-for="gender">
                <label> الجنس</label>
                <v-select
                  v-model="gender"
                  :options="optionGender"
                  :reduce="(val) => val.value"
                />
              </b-form-group>
            </b-col>
            <b-col md="1" xl="3">
              <b-button variant="purple" @click="addwork">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> إضافة العمل</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-card-code title=" الأعمال">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group>
          <div class="">
            <!-- <label>بحث</label> -->
            <div>
              <b-form-input
                v-model="searchTerm"
                placeholder="بحث"
                type="text"
                class="d-inline-block"
              />
            </div>
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            <!-- <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          /> -->
            <span class="text-nowrap">{{ props.row.name }}</span>
          </span>

          <!-- Column: Status -->
          <!-- <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span> -->

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'id'">
            <span>
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item-button
                  @click="
                    $router.push({
                      name: 'edit-work',
                      params: { id: props.row.id },
                    })
                  "
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>عرض</span>
                </b-dropdown-item-button>
                <!-- <b-dropdown-item-button >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>تعديل</span>
              </b-dropdown-item-button > -->
                <b-dropdown-item-button @click="deleteWork(props.row.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>حذف</span>
                </b-dropdown-item-button>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-code>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { useToast } from "vue-toastification/composition";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
} from "bootstrap-vue";
export default {
  deletWork(id) {
    this.$swal({
      icon: "warning",
      title: "هل انت متأكد من الحذف",
      confirmButtonText: "موافق",
      cancelButtonText: "الغاء",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "لا",
      },
    }).then((result) => {
      if (result.value) {
        let url = `/api/v1/work_types/${id}`;
        this.$http.delete(url).then((res) => {
          //console.log(res);
          this.getJobs();
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "تم الحذف  بنجاح",
                icon: "CheckIcon",
                variant: "success",
              },
            },
            {
              position: "top-center",
            }
          );
        });
      }
    });
  },

  name: "",

  components: {
    ToastificationContent,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
      workName: "",

      gender: "",

      columns: [
        {
          label: "العمل ",
          field: "name",
        },

        {
          label: "الخيارات",
          field: "id",
          sortable: true,
        },
      ],
      rows: [],
      searchTerm: "",
      id: "",
      optionGender: [],
    };
  },

  created() {
    this.getJobs();
    this.getGender();
  },

  methods: {
    deleteWork(id) {
      this.$swal({
        icon: "warning",
        title: "هل انت متأكد من الحذف",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      }).then((result) => {
        if (result.value) {
          let url = `/api/v1/work_types/${id}`;
          this.$http.delete(url).then((res) => {
            //console.log(res);
            this.getJobs();
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "تم الحذف  بنجاح",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              },
              {
                position: "top-center",
              }
            );
          });
        }
      });
    },
    addwork() {
      let data = {
        name: this.workName,
        gender: this.gender,
      };
      this.$http.post("/api/v1/work_types", data).then((res) => {
        //console.log(res);
        this.workName=''
        this.gender=''
        this.getJobs()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "تم إضافة العمل بنجاح",
            icon: "CheckIcon",
              variant: "success",
            },
          },
          {
            position: "top-center",
          });
      });
    },
    getGender() {
      this.$http.get("/api/v1/constants/genders").then((res) => {
        //console.log(res);
        let gender = res.data;
        gender.forEach((el) => {
          this.optionGender.push({ value: el.id, label: el.name });
        });
        //console.log(this.optionGender);
      });
    },

    getJobs() {
      this.$http.get("/api/v1/work_types").then((res) => {
        this.rows = res.data.data;
      });
    },

    //
  },
};
</script> 

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>